.base-Select-listbox {
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    // min-width: 320px;
    // max-width: 600px;
    // width: 100%;
}
.base-Option-root{
    // width: 100%;
    margin: auto;

}
.bottom-bar-scheduler {
    margin: auto;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .bottom-bar-scheduler {
      max-width: 700px; 
      margin: 0 auto;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {  
    .bottom-bar-scheduler {
      max-width: 700px; 
      margin: 0 auto;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .bottom-bar-scheduler {
      max-width: 700px; 
      margin: 0 auto;
    }
  } 
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .bottom-bar-scheduler {
      max-width: 700px; 
      margin: 0 auto;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .bottom-bar-scheduler {
      max-width: 700px; 
      margin: 0 auto;
    }
  }

  .bottom-bar-scheduler-shadow {
    background-color: #F5F2F9;
  }