.progress-tracker {
  .MuiStep-root {
    min-width: 80px;
    padding-left: 0;
    .MuiStepConnector-line {
      border-top-width: 2px;
    }
  }
  .MuiStepLabel-root {
    min-width: 80px;
    .MuiStepLabel-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .Mui-completed {
    svg {
      color: var(--primary);
    }
  }
  .Mui-active {
    svg {
      color: var(--primaryLight);
    }
  }
  .Mui-disabled {
    svg {
    }
  }
}
