body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
  }
  
  .thankyou-container {
    text-align: center;
    // border-radius: 10px;
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding-inline: 40px;
  }
  
  .thankyou-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333333; /* Dark text color */
  }
  
  .thankyou-container p {
    font-size: 18px;
    color: #555555; /* Medium text color */
    margin-bottom: 30px;
  }
  
  .icons {
    display: flex;
    justify-content: center;
  }
  
  .icon {
    font-size: 48px;
    margin: 0 20px;
    color: var(--primary); /* Mustard color */
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .icon:hover {
    transform: scale(1.2);
  }
  