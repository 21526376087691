body {
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
}

.appointment-details-container {
  max-width: 460px;
  margin-inline: auto;
}

@media screen and (min-width: 800px) {
  .appointment-details-container {
    background-color: rgba(247, 148, 32, 0.2);
  }
}
