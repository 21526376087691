.date-row {
    padding: .5rem;
    background: #F1F3F5;
    border-bottom: 1px solid #dcdcde;
    min-width: 6rem;
    text-align: center;
}
.slot-table {
    border: 1px solid #F1F3F5;
    border-radius: 8px;
    overflow-x: hidden;
    border: 1px solid #dcdcde;
    min-height: 127px;

}
.slot-button {
    background-color: #cf74470a;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    /* width: 66px; */
    font-size: 0.75rem;
    font-weight: 500;
    /* line-height: 1.68rem; */
    text-align: center;
    box-shadow: 0 0 0 .5px var(--primary);
    padding: 2px 8px;
    display: inline-block;
}
.appointment-card {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.20);
    background-color: #fff;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 20px;
    border-radius: 12px;
    max-width: 1088px;
    margin-inline: auto;
}
.appointment-card-title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #11181C;
    margin-bottom: .5rem;
}
.appointment-card-subtitle {
    color: #11181C;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.2rem;
}
.appointment-card-button {
    line-height: .875rem !important;
    font-size: 0.70rem !important;
}
.day-subtitle {
    font-size: 0.563rem;
    color: #687076 !important;
    font-weight: 500;
}
.day-title{
    font-size: .685rem;
    line-height: 0.75rem;
    color: #11181C !important;
    font-weight: 500;

}
.view-button {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
    font-size: .75rem;
    height: 1.5rem;
    font-weight: 500;
    align-items: center;
    padding: 4px 12px;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.20);
    margin-top: 8px;
    border-radius: 20px;
    cursor: pointer;
}
.next-available-button {
    margin-top: 28px;
    margin-bottom: 8px;
    margin-inline: 8px;
}
