.journey-renderer-container {
  width: 100%;
  height: 100vh;
  background-color: #F5F2F9;
}
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(65, 48, 62, 0.15);
  padding: 10px;
  text-align: center;
  z-index: 99;
}

.content {
  flex: 1;
  overflow-y: visible; /* Add vertical scroll if content exceeds height */
  // padding: 20px;
  // padding-bottom: 70px;
  background-color: #F5F2F9;
  // height: 86vh;
}
.content-width {
  background-color: white;
  border-radius: 8px;
}
.form-width {
  max-width: 600px;
}
.bottom-bar-shadow {
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
}

.bottom-bar {
  border-radius: 8px;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  text-align: center;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .journey-renderer-container {
    // padding-top: 150px;
    .content-padding {
      padding-top: 4rem;
      padding-bottom: 6rem;
    }
    .top-bar {
      // height: 150px;
      .title-text {
        font-size: 28px;
      }
    }
  }
  .content-width {
    max-width: 700px;
    margin: 0 auto;
  }
  .bottom-bar {
    max-width: 700px;
    margin: 0 auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .journey-renderer-container {
    // padding-top: 150px;
    .content-padding {
      padding-top: 4rem;
      padding-bottom: 6rem;
    }
    .top-bar {
      height: 150px;
      .title-text {
        font-size: 28px;
      }
    }
  }
  .content-width {
    max-width: 700px;
    margin: 0 auto;
  }

  .bottom-bar {
    max-width: 700px;
    margin: 0 auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .journey-renderer-container {
    // padding-top: 150px;
    .content-padding {
      padding-top: 8rem;
      padding-bottom: 6rem;
    }
    .top-bar {
      height: 150px;
      .title-text {
        font-size: 28px;
      }
    }
  }
  .content-width {
    max-width: 700px;
    margin: 0 auto;
  }

  .bottom-bar {
    max-width: 700px;
    margin: 0 auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .journey-renderer-container {
    // padding-top: 100px;
    .content-padding {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    .top-bar {
      height: 100px;
      display: flex;
      flex-direction: row;
      .title-text {
        font-size: 28px;
      }
    }
  }
  .content-width {
    max-width: 700px;
    margin: 0 auto;
  }

  .bottom-bar {
    max-width: 700px;
    margin: 0 auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .journey-renderer-container {
    // padding-top: 100px;
    .content-padding {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    .top-bar {
      .title-text {
        font-size: 28px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .content-width {
    max-width: 700px;
    margin: 0 auto;
  }

  .bottom-bar {
    max-width: 700px;
    margin: 0 auto;
  }
}

.button-primary-bg {
  background-color: #6366f1;
  border-radius: 24px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 24px;
  padding: 8px 36px;
  text-transform: uppercase;
  text-align: center;
}
.btn48-primary-bg:hover {
  background-color: #4338ca;
}
.button-primary-normal {
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 24px;
  border: 1px solid #fff;
  color: #4338ca;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 24px;
  padding: 8px 36px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border: 1px solid #4338ca;
}
.button-primary-normal:hover {
  color: #6366f1;
  background-color: #f5f4f5;
}
