.radio-common {
  min-width: 320px;
  width: 100%;
  max-width: 40rem;
  border-radius: 4px;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

.table-layout {
  .MuiTablePagination-displayedRows {
    margin: auto;
  }
  .MuiTablePagination-selectLabel {
    margin: auto;
  }
}

.checkbox-selector {
  font-family: Arial, sans-serif;
  width: 100%;

  .font-semibold {
    font-weight: 600;
    font-size: 18px;
    padding-top: 12px;
  }

  // span {
  //   color: #ff0000;
  // }

  .radio-common {
    display: flex;
    align-items: center;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type="checkbox"] {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .font-semibold {
      font-size: 16px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .font-semibold {
      font-size: 20px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .font-semibold {
      font-size: 22px;
    }
  }
}

.content-renderer {
  font-family: Arial, sans-serif;
  width: 100%;

  /* Media queries for responsiveness */

  @media only screen and (max-width: 767px) {
    .content-renderer {
      padding-left: 16px;
      padding-right: 16px;
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 768px) {
    .content-renderer {
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 992px) {
    .content-renderer {
      font-size: 22px;
    }
  }
}
.text-field {
  width: 100%;
}

.form-title {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: var(--primary-text-color);
  font-size: 1rem;
  font-weight: 600;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
.text-input {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 400;
  padding-inline: 8px;
  font-size: 1rem;
  color: #212636;
  box-sizing: border-box;
  cursor: text;
  margin-top: 8px;
  min-height: 40px;
  border-radius: 8px;
  border: 1px solid #dcdfe4;
  // border-bottom: 2px solid #dcdfe4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
}
.text-input:focus {
  border-bottom: 2px solid var(--inputFieldBorderColor);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.text-input:focus-visible {
  border-bottom: 2px solid var(--inputFieldBorderColor);
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.custom-radio-selector {
  input[type="radio"] {
    height: 1rem;
    width: 1rem;
    margin: -2px;
    margin-right: 0.5rem;
  }
  .custom-radio {
    /* hiding browser el */
    appearance: none;
    /* Safari support */
    -webkit-appearance: none;
    border: 0.2rem solid #fff;
    background-color: #e8e8e8;
    border-radius: 50%;
  }

  /* keyboard navigation focus appearance */
  .custom-radio:focus-visible {
    outline-offset: 0;
  }

  #box-shadow {
    box-shadow: 0 0 0 1px #999;
  }

  #box-shadow:hover {
    border-width: 0;
  }

  #box-shadow:checked {
    box-shadow: 0 0 0 1px var(--primary);
    background-color: var(--primary);
    border-width: 0.2rem;
  }
}

.single-checkbox {
  .checkbox-wrapper-4 {
    .cbx {
      padding: 6px 0px;
      .span-first {
        min-width: 18px;
      }
    }
    .cbx-flex {
      display: flex;
    }
    .cbx:hover {
      background: none;
    }
  }
}

input:invalid {
  // border: 2px solid red;
}
.base-Select-root {
  cursor: pointer;
}
.cursor_pointer {
  cursor: pointer !important;
}
.option_selector {
  padding-inline: 16px;
}
.option_selector:hover {
  box-shadow: 0 0 0 1px var(--primary);
  background-color: rgba(207, 116, 71, 0.04);
}

.border_purple {
  box-shadow: 0 0 0 1px var(--primary);
}
.bg_purple {
  background-color: rgba(207, 116, 71, 0.04);
}

.box-modal {
  background-color: #ffffff;
  color: #212636;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  background-image: none;
  border: 1px solid #dcdfe4;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  padding: 8px;
  width: 90% !important;
}
.box-modal:focus-visible {
  outline: 0;
}
.modal-title {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
}
.modal-subtitle {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.57;
  color: #667085;
}
.modal-button {
  min-height: 40x;
  font-size: 0.875rem;
  line-height: 28px;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 500;
  padding: 6px 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #32383e;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 28px;
  flex: 1 1 auto;
}
.modal-button-left {
}
.modal-button-right {
  background-color: var(--primary);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: white;
}

.cursor_default {
  cursor: default !important;
}

.primary_button {
  justify-content: center;
  cursor: pointer;
  vertical-align: middle;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: var(--buttonBorderRadius);
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: var(--primary);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.primary_button:hover {
  text-decoration: none;
  background-color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.outlined_button {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  vertical-align: middle;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: var(--buttonBorderRadius);
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(207, 116, 71, 0.5);
  color: var(--primary);
}
.outlined_button:hover {
  text-decoration: none;
  background-color: rgba(207, 116, 71, 0.04);
  border: 1px solid var(--primary);
}
.text_button {
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: var(--buttonBorderRadius);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--primary);
}
.text_button:hover {
  background-color: rgba(207, 116, 71, 0.04);
}
.date-picker {
  input {
    padding: 0;
    padding-inline: 12px;
    min-height: 40px;
  }
  .MuiFormControl-root {
    outline: 0;
    // border-radius: 8px;
    // border: 1px solid #dcdfe4;
    // border-bottom: 2px solid #dcdfe4;
    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  }
  .MuiInputBase-root {
    border-bottom: 2px solid #dcdfe4;
  }
  .Mui-focused {
    // border: 2px solid var(--primary);
    // box-shadow: 0 0 0 2px var(--primary);
    border-bottom: 2px solid var(--inputFieldBorderColor) !important;
    border: none;
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    outline: 0;
    border: none;
  }
}
.Mui-selected {
  background-color: var(--primary) !important;
}

.primary_large_button {
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.75;
  padding: 10px 14px;
  border-radius: var(--buttonBorderRadius);
}
.primary_large_button-inactive {
  background-color: var(--primaryLight);
  box-shadow: none;
}

.primary_large_button-inactive:hover {
  background-color: var(--primaryLight);
  box-shadow: none;
}

.primary_checked {
  background-color: var(--primary);
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border: 2px solid #dcdfe4;
  padding: 9px;
  margin-top: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.outlined_checked {
  background-color: white;
  text-align: center;
  color: var(--primary);
  font-weight: 600;
  font-size: 1rem;
  border: 2px solid #dcdfe4;
  padding: 9px;
  margin-top: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.font_georgia {
  font-family: Arial, Helvetica, sans-serif;
}
.text-huge {
  font-size: 1.85rem;
}
.text-small {
  font-size: 0.85rem;
}
.editable_div {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 1rem;
  box-sizing: border-box;
  cursor: text;
  // padding: 8px;
  // border-bottom: 2px solid #dcdfe4;
  padding: 8px;
  top: -13px;
}
.editable_span {
  color: var(--primary);
  font-weight: 600;
}
.non_editable_span {
  color: #939496;
  font-weight: 500;
}
.editable_span:focus {
  border: none;
  outline: none;
}
.editable_span:focus-visible {
  border: none;
  outline: none;
}
.text_white {
  color: white !important;
  caret-color: var(--primary);
  font-size: 1rem;
  font-weight: 600;
}
.status-bar {
  .MuiLinearProgress-root {
    background-color: white;
    border: 1px solid var(--primary);
    border-radius: 2px;
    // min-width: 300px;
  }
  .MuiLinearProgress-root::before {
    background-color: var(--primary);
  }
  // .css-ai7muv-JoyLinearProgress-root::after{
  //   content: "";
  //   display: block;
  //   border-radius: 50%;
  //   background-color: white;
  //   border: 1px solid var(--primary);
  //   position: absolute;
  //   right: 72px;
  //   height: 20px;
  //   width: 20px;
  // }
}
.primary_background {
  background-color: var(--primary);
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.primary_background:hover {
  background-color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
